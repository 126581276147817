import { createRouter, createWebHistory } from 'vue-router'
import { loadLayoutMiddleware } from "@/router/middleware/loadLayoutMiddleware";

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../pages/Admin/Main/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../pages/Admin/About/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/about/:id/editContent/:content_id',
    name: 'About_content',
    component: () => import('../pages/Admin/About/editCard.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/consumer',
    name: 'Consumer',
    component: () => import('../pages/Admin/Consumer/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/consumer/tab/create',
    name: 'Consumer_tab_create',
    component: () => import('../pages/Admin/Consumer/[createTab].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/consumer/tab/update/:id',
    name: 'Consumer_tab_update',
    component: () => import('../pages/Admin/Consumer/[updateTab].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/consumer/tab/content/:id',
    name: 'Consumer_tab_content',
    component: () => import('../pages/Admin/Consumer/[innerPage].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/consumer/tab/content/:id/create',
    name: 'Consumer_tab_content_create',
    component: () => import('../pages/Admin/Consumer/contents/[createContent].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/consumer/tab/content/:id/update/:content_id',
    name: 'Consumer_tab_content_update',
    component: () => import('../pages/Admin/Consumer/contents/[updateContent].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/subsidiary/',
    name: 'Subsidiary',
    component: () => import('../pages/Admin/Subsidiary/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/subsidiary/:id',
    name: 'Subsidiary_id',
    component: () => import('../pages/Admin/Subsidiary/[id].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/subsidiary/create',
    name: 'subsidiary_create',
    component: () => import('../pages/Admin/Subsidiary/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/subsidiary/update/:id',
    name: 'subsidiary_update',
    component: () => import('../pages/Admin/Subsidiary/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/subsidiary/:id/create_tab',
    name: 'subsidiary_create_tab',
    component: () => import('../pages/Admin/Subsidiary/tabs/create.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/subsidiary/:id/update_tab/:tab_id',
    name: 'subsidiary_update_tab',
    component: () => import('../pages/Admin/Subsidiary/tabs/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/subsidiary/:id/tabs_contents/:tab_id',
    name: 'subsidiary_create_tab_contents',
    component: () => import('../pages/Admin/Subsidiary/tabs/[inner].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/subsidiary/:id/tabs_contents/:tab_id/create',
    name: 'subsidiary_create_tab_content_create',
    component: () => import('../pages/Admin/Subsidiary/tabs/[createContent].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/subsidiary/:id/tabs_contents/:tab_id/update/:tab_id_content',
    name: 'subsidiary_create_tab_content_update',
    component: () => import('../pages/Admin/Subsidiary/tabs/[updateContent].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../pages/Admin/Services/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/services/create',
    name: 'Services_create',
    component: () => import('../pages/Admin/Services/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/services/update/:id',
    name: 'Services_update',
    component: () => import('../pages/Admin/Services/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../pages/Admin/Contacts/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/contacts/create',
    name: 'Contacts_create',
    component: () => import('../pages/Admin/Contacts/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/contacts/update/:id',
    name: 'Contacts_update',
    component: () => import('../pages/Admin/Contacts/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/vacancies',
    name: 'Vacancies',
    component: () => import('../pages/Admin/Vacancies/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/vacancies/create',
    name: 'Vacancies_create',
    component: () => import('../pages/Admin/Vacancies/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/vacancies/update/:id',
    name: 'Vacancies_update',
    component: () => import('../pages/Admin/Vacancies/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../pages/Admin/News/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/news/create',
    name: 'News_create',
    component: () => import('../pages/Admin/News/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/news/update/:id',
    name: 'News_update',
    component: () => import('../pages/Admin/News/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/users/all',
    name: 'Users all',
    component: () => import('../pages/Admin/Users/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/Auth/index.vue'),
    meta: {
      requiresAuth: false,
      layout: 'AppLayoutAuth'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../pages/404/index.vue'),
    meta: {
      layout: 'AppLayoutBasic'
    }
  },
]

const token = localStorage.getItem("authAdminToken")

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
})

router.beforeEach(loadLayoutMiddleware)

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
